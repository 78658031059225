<template>
    <!-- Loader -->
    <!-- <div id="preloader">
                        <div id="status">
                            <div class="spinner">
                                <div class="double-bounce1"></div>
                                <div class="double-bounce2"></div>
                            </div>
                        </div>
                    </div> -->
    <!-- Loader -->

    <!-- <div class="back-to-home rounded d-none d-sm-block">
        <a href="index.html" class="btn btn-icon btn-primary"><i data-feather="home" class="icons"></i></a>
    </div> -->
    <div class="row justify-content-center">
        <div class="offset-lg-4 col-lg-6">
            <div class="alert alert-outline-primary alert-pills" role="alert">
                <span class="badge rounded-pill bg-danger"> Info </span>
                <span class="alert-content"> Le service est en test ! </span>
            </div>
        </div>
    </div>
    <!-- Hero Start -->
    <div class="row">
        <div class="col-lg-8">
            <section class="bg-home d-flex align-items-center position-relative"
                style="background: url('assets/images/hotspot/composant-24-–-1.png') right center no-repeat; background-size: 120%;">
                <!-- <div class="bg-overlay bg-overlay-white"></div> -->
                <div class="container">
                    <div class="row justify-content-start">
                        <div class="offset-lg-1 col-lg-6" style="margin-top:100px">
                            <div class="card login-page bg-white shadow rounded border-0 mt-4">
                                <div class="card-body">
                                    <form>
                                        <h5 class="text-center mb-3 mt-2">Récuperation</h5>
                                        <div class="row mb-4 mt-2">
                                            <div class="col-lg-12">
                                                <ul class="nav nav-pills nav-justified flex-column flex-sm-row rounded mt-2"
                                                    id="pills-tab" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link rounded active" id="pills-smart-tab"
                                                            data-bs-toggle="pill" href="#pills-smart" role="tab"
                                                            aria-controls="pills-smart" aria-selected="false">
                                                            <div class="text-center">
                                                                <h6 class="mb-0">Téléphone</h6>
                                                            </div>
                                                        </a>
                                                        <!--end nav link-->
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link rounded" id="pills-cloud-tab"
                                                            data-bs-toggle="pill" href="#pills-cloud" role="tab"
                                                            aria-controls="pills-cloud" aria-selected="false">
                                                            <div class="text-center">
                                                                <h6 class="mb-0">Email</h6>
                                                            </div>
                                                        </a>
                                                        <!--end nav link-->
                                                    </li>
                                                    <!--end nav item-->
                                                    <!--end nav item-->
                                                    <!--end nav item-->
                                                </ul>
                                                <!--end nav pills-->
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end row-->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div class="tab-pane fade" id="pills-cloud" role="tabpanel"
                                                        aria-labelledby="pills-cloud-tab">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="mb-3">
                                                                    <div class="form-icon position-relative">
                                                                        <i data-feather="mail"
                                                                            class="fea icon-sm icons"></i>
                                                                        <input type="email" class="form-control ps-5"
                                                                            placeholder="xxx@gmail.com" name="email"
                                                                            required="">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--end teb pane-->

                                                    <div class="tab-pane fade show active" id="pills-smart"
                                                        role="tabpanel" aria-labelledby="pills-smart-tab">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <!-- <label for="email" class="form-label">Email <span class="text-muted">(Optional)</span></label> -->
                                                                <div class="mb-3">
                                                                    <div class="form-icon position-relative">
                                                                        <i data-feather="phone"
                                                                            class="fea icon-sm icons"></i>
                                                                        <input type="phone" class="form-control ps-5"
                                                                            placeholder="Numéro Téléphone" name="email"
                                                                            required="">
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <!--end teb pane-->
                                                </div>
                                                <!--end tab content-->
                                            </div>
                                            <!--end col-->
                                        </div>
                                        <!--end row-->
                                        <!--end row-->
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault">
                                                    <label class="form-check-label" for="flexCheckDefault">Je ne suis
                                                        pas un robot

                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <button class="btn btn-outline-success btn-sm rounded-md w-100"
                                                    type="submit">Récuperez</button>
                                            </div>
                                            <!--end col-->

                                            <div class="col-12 text-center mt-4">
                                                <small>
                                                    <span class="text-muted me-2">Si vous avez un pass ?</span>
                                                    <router-link to="/login" class="text-dark fw-bold ">
                                                        <u>Connectez-vous</u>
                                                    </router-link>
                                                </small>
                                            </div>
                                            <!--end col-->
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!---->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </section>
        </div>
        <div class="col-lg-2">
            <h2 style="color: #BADEFD;font-weight: bold;">HOTSPOT</h2>
        </div>
        <div class="col-lg-2">
            <section class="bg-home d-flex align-items-center"
                style="background: url('assets/images/hotspot/ellipse.png')  no-repeat;"></section>
        </div>
    </div>
    <!--end section-->
    <!-- Hero End -->

</template>

<script>
    import feather from 'feather-icons'
    import { onMounted } from 'vue'
    export default {
        name: 'Recuperate',

        setup() {
            onMounted(() => {
                feather.replace()
            })
        }

    }
</script>